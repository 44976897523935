import React, { useEffect, useState} from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
    Box,
    Flex,
    Heading,
    Text,
    Button,
    Select
} from '@chakra-ui/react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useStore from '../../context/StoreContext'
import { PaintingSlider } from './PaintingSlider'
import PaintingGallery from './PaintingGallery'

export function PaintingProductCard({ product }) {
    
    const { addVariantToCart, cart, checkout } = useStore();

    // useEffect(() => {
    //     addVariantToCart(product, 1);
    //     console.log(product)
    // }, []);

    const [quantity, setQuantity] = useState(1);
    
    const hasImage = product.media.length === 1;
    const hasGallery = product.media.length > 1;


    const available = product.variants[0].availableForSale === true;

    const handleChange = (event) => {
        setQuantity(event.target.value);
    };

    const handlePurchase = async () => {
        // await addVariantToCart before moving to checkout
        await addVariantToCart(product, quantity);
        window.location.assign(checkout.webUrl);
    };

    return (
        <Box p="2" flexDirection="column" w="100%">
            <Flex borderRadius="md" boxShadow="md" p="4" flexDirection="column" w="100%" h="100%" justifyContent="space-between" alignItems="center">
                {hasImage 
                    ? (
                         <Box w="100%" mb="4" position="relative">
                             <GatsbyImage
                                image={getImage(product.media[0].image.localFile)}
                                objectFit='cover'
                                style={{
                                    width: '100%',
                                   // height: '280px',
                                }}
                        />
                         </Box>
                    ) : hasGallery
                        ? (
                            <PaintingGallery photos={product.media} />
                        )
                    : (
                       null
                    )
                }
                <Box textAlign="center" mb="4">
                    <Heading as="h4" variant="h4" fontSize="1.5rem" textAlign='center'>{product.title}</Heading>
                    <Text fontWeight="bold" fontSize="1.75rem" color="snowscapes.600">
                        {`$${product.priceRangeV2.maxVariantPrice.amount.toLocaleString("en-US")} each`}
                    </Text>
                    <Text mb="0">
                        Quanity
                    </Text>
                    <Select value={quantity} onChange={handleChange}>
                        {[...Array(10).keys()].map((num) => (
                            <option key={num + 1} value={num + 1}>
                                {num + 1}
                            </option>
                        ))}
                    </Select>
                </Box>
                
                
                <Box textAlign="center">
                
                    <Button variant='snowscapes' onClick={handlePurchase}>
                        {available ? (`Buy ${quantity } ${quantity > 1 ? 'Tickets' : 'Ticket'}`) : 'Sold Out'}
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
}