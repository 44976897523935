import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function PaintingGallery({ photos }) {

    const [open, setOpen] = useState(false);
    
    const images = photos.map((photo) => {
        return {
            src: photo.image.localFile,
            caption: photo.altText,
        }
    })

    const GatsbySlide = ({ slide }) => {
        return (
            <Box style={{ position: "relative", width: '100%', height: '100%' }}>
                <GatsbyImage image={getImage(slide.src)} style={{height: "100%", width: "100%", objectFit: "contain"}} objectFit='contain'/>
            </Box>
        )
    }

    return (
        <>
        <Box onClick={() => setOpen(true)} h="100%" w="100%" position="relative" cursor="pointer">
            <Box style={{ position: "absolute", top: -4, right: 0, padding: "10px", zIndex: 1, color: 'white' }}>
                <ExternalLinkIcon />
            </Box>
            <GatsbyImage image={getImage(photos[0].image.localFile)} objectFit='cover'
                style={{
                    width: '100%',
                    height: '280px',
                }}/>
        </Box>
         <Lightbox 
            open={open}
            close={() => setOpen(false)}
            slides={images}
            render={{
                slide: GatsbySlide,
            }}
         />
        </>
    )
}